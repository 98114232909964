import appStrings from '@/app/utility/string.utility';
export default {
  name: 'CrmProjectLicense',
  props: {
    parentCrmProjectData: Object
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      projectId: null,
      licenseTableIndex: 0,
      crmProjLicenseData: [
        {
          license_type_value_code: null,
          license_type_value_meaning: null,
          license_number: null,
          fsi: null,
          crm_project_license_id: 0
        }
      ],
      crmProjLicenseFields: [
        {
          key: 'crm_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_project_license_id',
          class: 'd-none'
        },
        {
          key: 'license_type_value_code',
          class: 'd-none'
        },
        {
          key: 'license_type_value_meaning',
          label: 'License Type'
        },
        {
          key: 'license_number',
          Label: 'License No.'
        },
        {
          key: 'fsi',
          label: 'FSI'
        },
        {
          key: 'seq'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmProjectLicense();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.projectId = this.parentCrmProjectData.crm_project_id;
    this.getCrmProjectLicense();
  },
  methods: {
    getCrmProjectLicense() {
      const payload = {
        crmProjectId: this.projectId
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/getCrmProjectLicense', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          if (results && results.length > 0) {
            this.crmProjLicenseData = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditCrmProjectLicense() {
      const crmProjectLicenseList = this.crmProjLicenseData.map(key => {
        return {
          crm_project_license_id: key.crm_project_license_id,
          fsi: key.fsi,
          license_number: key.license_number,
          license_type_value_code: key.license_type_value_code
        };
      });
      const payload = {
        crm_project_id: this.projectId,
        crm_project_license_list: crmProjectLicenseList
          ? crmProjectLicenseList
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmProjectLicense', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.crmProjLicenseData = results;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      this.crmProjLicenseData[
        this.licenseTableIndex
      ].license_type_value_meaning = item.value_meaning;
      this.crmProjLicenseData[this.licenseTableIndex].license_type_value_code =
        item.value_code;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.licenseTableIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.crmProjLicenseData.push({
          license_type_value_code: null,
          license_type_value_meaning: null,
          license_number: null,
          fsi: null,
          crm_project_license_id: 0
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.crmProjLicenseData.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'LICENSE TYPE') {
        this.crmProjLicenseData[index].license_type_value_meaning = null;
        this.crmProjLicenseData[index].license_type_value_code = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
