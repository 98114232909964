var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"fixed":"top"}},[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-alert',{attrs:{"variant":_vm.isSuccess ? 'success' : 'danger',"show":_vm.showAlert,"dismissible":"","fade":""},on:{"dismissed":function($event){_vm.showAlert = false}}},[_vm._v(_vm._s(_vm.responseMsg))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-0 text-centre pl-3",staticStyle:{"padding-top":"5px"},attrs:{"md":"12"}},[_c('b-table',{staticClass:"bg-white",attrs:{"outlined":"","striped":"","hover":"","sticky-header":"","items":_vm.crmProjLicenseData,"fields":_vm.crmProjLicenseFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(license_type_value_meaning)",fn:function(crmProjLicenseData){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"type":"text","required":"","readonly":"","title":crmProjLicenseData.item.license_type_value_meaning,"disabled":!_vm.editMode},on:{"click":function($event){return _vm.openValueSetModal('LICENSE TYPE', crmProjLicenseData.index)}},model:{value:(crmProjLicenseData.item.license_type_value_meaning),callback:function ($$v) {_vm.$set(crmProjLicenseData.item, "license_type_value_meaning", $$v)},expression:"crmProjLicenseData.item.license_type_value_meaning"}}),(
                crmProjLicenseData.item.license_type_value_meaning && _vm.editMode
              )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('LICENSE TYPE', crmProjLicenseData.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"cell(license_number)",fn:function(crmProjLicenseData){return [_c('b-form-input',{attrs:{"type":"text","required":"","title":crmProjLicenseData.item.license_number,"disabled":!_vm.editMode},model:{value:(crmProjLicenseData.item.license_number),callback:function ($$v) {_vm.$set(crmProjLicenseData.item, "license_number", $$v)},expression:"crmProjLicenseData.item.license_number"}})]}},{key:"cell(fsi)",fn:function(crmProjLicenseData){return [_c('b-form-input',{attrs:{"type":"text","required":"","disabled":!_vm.editMode},model:{value:(crmProjLicenseData.item.fsi),callback:function ($$v) {_vm.$set(crmProjLicenseData.item, "fsi", $$v)},expression:"crmProjLicenseData.item.fsi"}})]}},{key:"cell(seq)",fn:function(data){return [_vm._v(_vm._s(data.index + 1)+" ")]}},{key:"cell(add)",fn:function(){return [_c('div',{on:{"click":function($event){return _vm.addNewRow()}}},[_c('b-img',{attrs:{"src":require('@/assets/imgs/add.png'),"fluid":"","alt":"Vatika image"}})],1)]},proxy:true},{key:"cell(remove)",fn:function(crmProjLicenseData){return [(
              crmProjLicenseData.index > 0 &&
                crmProjLicenseData.item.crm_project_license_id === 0
            )?_c('div',{on:{"click":function($event){return _vm.removeRow(
                crmProjLicenseData.index,
                crmProjLicenseData.item.crm_project_license_id
              )}}},[_c('b-img',{attrs:{"src":require('@/assets/imgs/delete.png'),"fluid":"","alt":"Vatika image"}})],1):_vm._e()]}}])})],1)],1),(_vm.showValueSetModal)?_c('valueSetModal',{on:{"closeValueSetModal":_vm.closeValueSetModal,"selectedvalueSet":_vm.selectedvalueSet}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }