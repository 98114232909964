import appStrings from '@/app/utility/string.utility';
export default {
  name: 'CrmProjectArea',
  props: {
    parentCrmProjectData: Object
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      projectId: null,
      projectAreaId: 0,
      areaType: null,
      superStructArea: 0,
      defaultValue: {
        value: null,
        text: null
      },
      superStructAreaUnit: {
        value: null,
        text: null
      },
      subStructArea: 0,
      subStructAreaUnit: {
        value: null,
        text: null
      },
      landArea: 0,
      landAreaUnit: {
        value: null,
        text: null
      },
      basementArea: 0,
      basementAreaUnit: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.getUomClass();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmProjectArea();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.projectId = this.parentCrmProjectData.crm_project_id;
    this.getCrmProjectArea();
  },
  methods: {
    getCrmProjectArea() {
      const payload = {
        crmProjectId: this.projectId
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/getCrmProjectArea', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          this.projectId = results.crm_project_id;
          this.projectAreaId = results.crm_project_area_id;
          this.superStructArea = results.super_structure_area;
          this.superStructAreaUnit = {
            value: results.super_structure_area_uom_value_code,
            text: results.super_structure_area_uom_value_meaning
          };
          this.subStructArea = results.sub_structure_area;
          this.subStructAreaUnit = {
            value: results.sub_structure_area_uom_value_code,
            text: results.sub_structure_area_uom_value_meaning
          };
          this.landArea = results.land_area;
          this.landAreaUnit = {
            value: results.land_area_uom_value_code,
            text: results.land_area_uom_value_meaning
          };
          this.basementArea = results.basement_area;
          this.basementAreaUnit = {
            value: results.basement_area_uom_value_code,
            text: results.basement_area_uom_value_meaning
          };
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditCrmProjectArea() {
      const payload = {
        basement_area: parseInt(this.basementArea),
        basement_area_uom_value_code: this.basementAreaUnit.value,
        land_area: parseInt(this.landArea),
        land_area_uom_value_code: this.landAreaUnit.value,
        crm_project_area_id: this.projectAreaId,
        crm_project_id: this.projectId,
        sub_structure_area: parseInt(this.subStructArea),
        sub_structure_area_uom_value_code: this.subStructAreaUnit.value,
        super_structure_area: parseInt(this.superStructArea),
        super_structure_area_uom_value_code: this.superStructAreaUnit.value
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmProjectArea', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.projectAreaId = results.crm_project_area_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.superStructAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.subStructAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.landAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.basementAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode, areaType) {
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, areaType) {
      if (vsetCode === 'UOM') {
        if (areaType === 'superStructure') {
          this.superStructAreaUnit = this.defaultValue;
        } else if (areaType === 'subStructure') {
          this.subStructAreaUnit = this.defaultValue;
        } else if (areaType === 'landArea') {
          this.landAreaUnit = this.defaultValue;
        } else if (areaType === 'basementArea') {
          this.basementAreaUnit = this.defaultValue;
        }
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
