import DatePicker from 'vue2-datepicker';
import Organization from '../../../admin/organization';
import PrjProject from '../../../project/defineProjects';
import Address from '../../../admin/address';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'AddProjects',
  props: {
    parentCrmProjectData: Object
  },
  components: {
    DatePicker,
    Organization,
    PrjProject,
    Address
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      childName: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      parent_value_set_id: null,
      tab: null,
      tempParentData: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      masterPrj: {
        value: null,
        text: null
      },
      sector: {
        value: null,
        text: null
      },
      pocket: {
        value: null,
        text: null
      },
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      prjProject: {
        value: null,
        text: null
      },
      projectType: {
        value: null,
        text: null
      },
      address: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      projectCode: null,
      projectName: null,
      projectId: 0,
      startDate: null,
      endDate: null,
      version: null,
      active: true
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmProject();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.projectId });
        }
      }
    });
    if (
      this.parentCrmProjectData !== null &&
      this.parentCrmProjectData !== undefined
    ) {
      this.masterPrj = {
        value: this.parentCrmProjectData.crm_master_project_id,
        text: this.parentCrmProjectData.crm_master_project_name
      };
      this.sector = {
        value: this.parentCrmProjectData.crm_sector_id,
        text: this.parentCrmProjectData.crm_sector_name
      };
      this.pocket = {
        value: this.parentCrmProjectData.crm_pocket_id,
        text: this.parentCrmProjectData.crm_pocket_name
      };
      this.leName = {
        value: this.parentCrmProjectData.le_id,
        text: this.parentCrmProjectData.le_name
      };
      this.ouName = {
        value: this.parentCrmProjectData.ou_id,
        text: this.parentCrmProjectData.ou_name
      };
      this.prjProject = {
        value: this.parentCrmProjectData.project_project_id,
        text: this.parentCrmProjectData.project_project_name
      };
      this.projectType = {
        value: this.parentCrmProjectData.crm_project_type_vset,
        text: this.parentCrmProjectData.crm_project_type_meaning
      };
      this.address = {
        value: this.parentCrmProjectData.address_id,
        text: this.parentCrmProjectData.full_address
      };
      this.projectCode = this.parentCrmProjectData.crm_project_code;
      this.projectId = this.parentCrmProjectData.crm_project_id;
      this.projectName = this.parentCrmProjectData.crm_project_name;
      this.active = this.parentCrmProjectData.active;
      this.startDate = this.parentCrmProjectData.start_date;
      this.endDate = this.parentCrmProjectData.end_date;
    } else {
      this.editMode = true;
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'Organization') {
        return (this.childName = 'Organization');
      } else if (tab === 'PrjProject') {
        return (this.childName = 'PRJ Project');
      } else if (tab === 'Address') {
        return (this.childName = 'Address');
      }
    },
    addEditCrmProject() {
      const payload = {
        address_id: this.address.value,
        attribute_1: this.attribute1,
        attribute_2: this.attribute2,
        attribute_3: this.attribute3,
        attribute_4: this.attribute4,
        attribute_5: this.attribute5,
        le_id: this.leName.value,
        ou_id: this.ouName.value,
        project_project_id: this.prjProject.value,
        crm_sector_id: this.sector.value,
        crm_pocket_id: this.pocket.value,
        crm_project_code: this.projectCode,
        crm_project_id: this.projectId,
        crm_project_name: this.projectName,
        crm_project_type_vset: this.projectType.value
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmProject', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateCrmProjectList');
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'CRM_MASTER_PROJECT') {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.masterPrj.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CRM_SECTOR) {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.sector.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CRM_POCKET) {
        this.pocket = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else {
        this.projectType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (
        vsetCode === 'PRJ_TYPE' ||
        vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST
      ) {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openCloseOu(flag, orgType, component) {
      this.tab = component;
      this.orgType = orgType;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
      this.setChildName(this.tab);
      this.showModalComponent = flag;
    },
    selectedOrg(item) {
      if (this.orgType === 'LE') {
        this.leName.value = item.org_id;
        this.leName.text = item.org_name;
      } else if (this.orgType === 'OU') {
        this.ouName.value = item.org_id;
        this.ouName.text = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.location.value = item.org_id;
        this.location.text = item.org_name;
      }
      this.showModalComponent = false;
    },
    getAddressObj(item) {
      this.showModalComponent = false;
      this.address.value = item.address_id;
      this.address.text = item.completeAddress;
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.prjProject = {
        value: item.project_id,
        text: item.project_name
      };
    },
    hideModalComponent(flag, component) {
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.masterPrj.value) {
        this.masterPrj = this.defaultValue;
      } else if (vsetCode === this.sector.value) {
        this.sector = this.defaultValue;
      } else if (vsetCode === this.pocket.value) {
        this.pocket = this.defaultValue;
      } else if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
      } else if (vsetCode === this.ouName.value) {
        this.ouName = this.defaultValue;
      } else if (vsetCode === this.projectType.value) {
        this.projectType = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
