import CrmProjectLicense from './crmProjectLicense';
import AddCrmProject from '../addCrmProject';
import CrmProjectArea from './crmProjectArea';
import OtherSpec from './crmProjectOtherSpec';
export default {
  name: 'CrmProjectDetails',
  props: {
    parentCrmProjectData: Object
  },
  components: {
    CrmProjectLicense,
    AddCrmProject,
    CrmProjectArea,
    OtherSpec
  },
  data() {
    return {
      actionName: null,
      loader: false,
      activeTab: 'CrmProjectDetails'
    };
  },
  mounted() { },
  methods: {
    selectedTab(tabValue) {
      this.activeTab = tabValue;
    },
    closeTabs() {
      this.$emit('closeCrmProjectDetail');
      this.$emit('updateCrmProjectList');
    },
    buttonAccessPermissions(actionName) {
      this.actionName = actionName;
    }
  }
};