import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import AddCrmProject from './addCrmProject';
import PrjProject from '../../project/defineProjects';
import Organization from '../../admin/organization';
import CrmProjectDetails from './crmProjectDetails';
export default {
  name: 'DefineProject',
  watch: {
    currentPage: function() {
      this.getCrmProjectList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCrmProjectList();
    }
  },
  props: {
    showProjectComponent: Boolean
  },
  components: {
    AddCrmProject,
    Organization,
    CrmProjectDetails,
    PrjProject
  },
  data() {
    return {
      unsubscribe: null,
      parent_value_set_id: null,
      showModalComponent: false,
      showValueSetModal: false,
      showDetails: false,
      orgType: null,
      openOuModal: false,
      loader: false,
      editMode: false,
      payload: {},
      showAlert: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      tab: null,
      childName: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      prjProject: {
        value: null,
        text: null
      },
      projType: {
        value: null,
        text: null
      },
      active: true,
      masterPrj: {
        value: null,
        text: null
      },
      projectCode: null,
      projectName: null,
      parentCrmProjectData: null,
      crmProjectData: [],
      defaultValue: {
        value: null,
        text: null
      },
      crmProjectFields: [
        {
          key: 'crm_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_project_code',
          label: 'Project Code'
        },
        {
          key: 'crm_project_name',
          label: 'Project Name'
        },
        {
          key: 'project_project_id',
          class: 'd-none'
        },
        {
          key: 'project_project_name',
          class: 'd-none'
        },
        {
          key: 'crm_pocket_name',
          class: 'd-none'
        },
        {
          key: 'crm_pocket_id',
          class: 'd-none'
        },
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'le_short_name',
          class: 'd-none'
        },
        {
          key: 'ou_id',
          class: 'd-none'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'address'
        },
        {
          key: 'version'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'AddCrmProject');
          this.parentCrmProjectData = null;
        }
        if (actionName === 'download' && !this.showModalComponent) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'crmInventoryProperty/getCrmProjectList',
            'crm-project',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      if (tab === 'AddCrmProject') {
        return (this.childName = 'Add CRM Project');
      } else if (tab === 'PrjProject') {
        return (this.childName = 'PRJ Project');
      } else if (tab === 'Organization') {
        return (this.childName = 'Organization');
      } else if (tab === 'CrmProjectDetails') {
        return (this.childName = 'CRM Project Details');
      }
    },
    getCrmProjectList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        crm_project_name: this.projectName,
        crm_project_code: this.projectCode,
        le_id: this.leName.value,
        ou_id: this.ouName.value,
        project_project_id: this.prjProject.value,
        crm_project_type_vset: this.projType.value
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/getCrmProjectList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.crmProjectData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      this.parentCrmProjectData = rowData;
      this.$emit('selectedCrmProj', this.parentCrmProjectData);
      this.showDetails = true;
    },
    closeCrmProjectDetail() {
      this.showDetails = false;
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.prjProject = {
        value: item.project_id,
        text: item.project_name
      };
    },
    hideModalComponent(flag, component) {
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    openCloseOu(flag, orgType, component) {
      this.tab = component;
      this.orgType = orgType;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
      this.setChildName(this.tab);
      this.showModalComponent = flag;
    },
    selectedOrg(item) {
      if (this.orgType === 'LE') {
        this.leName.value = item.org_id;
        this.leName.text = item.org_name;
      } else if (this.orgType === 'OU') {
        this.ouName.value = item.org_id;
        this.ouName.text = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.location.value = item.org_id;
        this.location.text = item.org_name;
      }
      this.showModalComponent = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === 'PRJ_TYPE') {
        this.projType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (
        vsetCode === 'PRJ_TYPE' ||
        vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST
      ) {
        this.parent_value_set_id = null;
      } else {
        this.parent_value_set_id = this.leName.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    updateCrmProjectList() {
      this.getCrmProjectList();
    },
    resetSearchFilters() {
      this.leName = this.defaultValue;
      this.ouName = this.defaultValue;
      this.prjProject = this.defaultValue;
      this.projType = this.defaultValue;
      this.projectCode = null;
      this.projectName = null;
      this.parent_value_set_id = null;
      this.crmProjectData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
      } else if (vsetCode === this.ouName.value) {
        this.ouName = this.defaultValue;
      } else if (vsetCode === this.projType.value) {
        this.projType = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
